import React, {useEffect} from 'react';
import './Reviews.css';
import reviews_list from './Reviews.json';
import t from './Translations';

let sorted_reviews = reviews_list.reverse();

function Reviews(props) {
  //show logo always on this page
  useEffect(() => {
    document.getElementById("App-menu").classList.add("App-menu-logo");
  
    return () => {
      document.getElementById("App-menu").classList.remove("App-menu-logo");
    }
  }, []);

  //TODO sort date - new = top
  return (
    <div style={{width:'100%'}} className={`ReviewPage ${ props.isMobile ? 'about-mobile' : '' }`} >
      <h1>{t.translate('reviews_page_title')}</h1>
      <div className="ReviewPage-list">
        {sorted_reviews.map((review)=>{
          let meta = review.meta;
          return <div className="ReviewPage-review">
            {/*review.restaurant*/}
            <div className="ReviewPage-review-image-container">
              <img src={meta.image} alt={review.restaurant} title={review.restaurant} className="ReviewPage-review-image" />
            </div>
            <div className="ReviewPage-review-content">
              {/*
              <div className="ReviewPage-review-date"> 
                {review["date"]} 
              </div>
              */}
              <h3 className="ReviewPage-review-header">
                <b>{meta.title}</b>
                {(meta.author && meta.title.indexOf(meta.author) === -1 ? (" | " + meta.author) : '')}
                {(meta.publisher && meta.title.indexOf(meta.publisher) === -1 ? (" | " + meta.publisher) : '')}
              </h3>
              {meta.description && meta.description !== "***" && meta.description !== "&nbsp;" ? <p className="ReviewPage-review-description">{meta.description}</p> : ''}
            </div>
            {meta.logo ? <img src={meta.logo} alt={meta.publisher} className="ReviewPage-review-logo" />:''}
            <div className="ReviewPage-review-footer">
              <a href={review.link} target="_blank">
                לכתבה המלאה...
              </a>
            </div>
          </div>
        })}
      </div>
    </div>
  );
}

export default Reviews;
