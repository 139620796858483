import translations from 'translations';
import he from './locales/he-IL.json';
import en from './locales/en-US.json';
var lastLang = "he";
var t = {
    translate: translations(he),
    lastLang: "he",
    /*
        Input lang name - either 'he' or 'en'
        For any other value returns error
    */
    setLang: (lang) => {
        if ((lang !== 'he') && (lang !== 'en')) {
            console.log('Wrong language input');
        } else {
            if (lang !== lastLang) {
                lastLang = lang;
                if (lang === 'he') {
                    t.translate = translations(he);
                    t.isEn = false;
                } else {
                    t.translate = translations(en);
                    t.isEn = true;
                }
            }
        }
    },
    isEn: false
};
export default t;