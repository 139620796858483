import React from 'react';
import './CardsLayout.css';
import Card from './Card';
import t from './Translations';

class CardsLayout extends React.Component {
    render() {
        return <div>
          <div className="resty-cards-layout-container">
            <div className={`resty-cards-layout ${t.isEn ? 'card-layout-ltr' : ''}`}>
              {this.props.cards.map((card) => <Card data={card} order={card.overall_order} key={card.key} />)}
              {(this.props.cards.length === 50) ? <Card data={'list-end-placeholder'} key={'list-end-placeholder'} /> : ''}
            </div>
          </div>
          {this.props.canAdd ?
            <div className="resty-more-cards" onClick={this.props.addCards}>
              {t.translate('more_button')}
            </div> 
            : ''
          }
        </div>;
    }
}
export default CardsLayout;
