import React, {useEffect} from "react";
import { withStyles } from "@material-ui/core/styles";
import Tabs from "@material-ui/core/Tabs";

import Chip from "@material-ui/core/Chip";
import CircleIcon from "@material-ui/icons/CheckCircle";
import t from './Translations';

import './CategoryList.css';

const StyleTabs = withStyles({
  indicator: {
    display: "none"
  }
})(Tabs);

const StyleChip = withStyles({
  root: {
    marginTop: "8px",
    marginRight: "5px",
    fontSize: "10px",
    border: 'none',
    backgroundColor: 'transparent',
    color: '#ADADAD'
  }
})(Chip);

let intial_props = [];
let prevCat = [];
let prevLang = null;
export default function CategoryList(props) {
    const [chips, setChips] = React.useState(intial_props);
    
    useEffect(() => {
        if (props.categories.length !== prevCat.length || props.lang !== prevLang) {
            prevCat = props.categories;
            prevLang = props.lang;
            intial_props = props.categories.map((cat,i)=>{
                return { name: t.translate("cat_"+cat), cat_key: cat, selected: false, key: i+2 };
            });
            intial_props.unshift({ name: t.translate("cat_all"), cat_key: "all", selected: true, key: 1 });
            setChips(intial_props);
        }
    },[props]);
    
    const handleClick = categoryKey => () => {
        let newOptions;
        if (categoryKey === 1) {
            // select all only
            newOptions = chips.map(item =>
                item.key === 1
                ? { ...item, selected: true }
                : { ...item, selected: false }
            );
        } else {
            newOptions = chips.map(item => {
                if (item.key === 1) { //deselect all
                    return { ...item, selected: false };
                } else if (item.key === categoryKey) { //select current
                    return { ...item, selected: !item.selected };
                } else { //dont change others
                    return { ...item };
                }
            });
            //if all got unselected - select all :)
            if (newOptions.every(item=>!item.selected)) {
                newOptions[0].selected = true;
            }
        }
        setChips(newOptions);
        
        let selected = newOptions.filter(item=>item.selected);
        if (selected.length === 1 && selected[0].key === 1) {
            props.updateCategory(null);
        } else {
            props.updateCategory(selected);
        }
    };

    return (
        <div className="resty-categories-tab-container">
            <StyleTabs
                value={0}
                onChange={() => {}}
                variant="scrollable"
                scrollButtons="on"
                indicatorColor="primary"
                textColor="primary"
                aria-label="scrollable force tabs example"
            >
                {chips.map(category => (
                    <StyleChip
                        variant="outlined"
                        label={`${category.name}`}
                        key={`${category.cat_key}`}
                        onClick={handleClick(category.key)}
                        icon={category.selected ? <CircleIcon /> : ""}
                    />
                ))}
            </StyleTabs>
        </div>
    );
}