import React, { useState, useEffect } from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  Redirect
} from "react-router-dom";
import './App.css';
import ScrollToTop from './ScrollToTop';
import {Helmet} from "react-helmet";

import firebase from './Firebase';
import List from './List';
import Reviews from './Reviews';
import About from './About';
import Rest from './Rest';
import NotFoundPage from './NotFoundPage'
import t from './Translations';

import hamburger from './img/hamburger.svg';
import x from './img/x.svg';
import leaves from "./img/leaves.svg";

firebase.init();

let headerElement = null;
let isScroll = false;
window.addEventListener('scroll', function(e) {
  if (!headerElement) {
    headerElement = document.getElementById("App-menu");
  }

  if (window.scrollY > 0) {
    if (!isScroll) {
      isScroll = true;
      headerElement.classList.add("App-menu-scroll");
    }
  } else {
    if (isScroll) {
      isScroll = false;
      headerElement.classList.remove("App-menu-scroll");
    }
  }
});

window.onbeforeunload = function () {
  window.scrollTo(0, 0);
  return null;
}

//for iphone
window.onload = function() {
  setTimeout (function () {
    window.scrollTo(0, 0);
  }, 100); //100ms for example
 }


function checkIsMobile() {
  return window.innerWidth <= 600;
}

const LANGS = {
  he : 'he',
  en: 'en'
}

function getLang() {
  return (window.location.href.indexOf('/en') > -1) ? LANGS.en : LANGS.he;
}

function App() {
  const [menuVisible, setMenuVisible] = useState(false);
  const [isMobile, setIsMobile] = useState(checkIsMobile());
  const [lang, setLang] = useState(getLang());
  t.setLang(lang);
  let otherLang = t.isEn ? LANGS.he : LANGS.en;

  function verifyMobile() {
    setIsMobile(checkIsMobile());
  }

  useEffect(() => {
    window.addEventListener('resize', verifyMobile);
  
    return () => {
      window.removeEventListener('resize', verifyMobile);
    }
  }, []);

  return (
    <Router>
        {(lang === 'en')?
          <Helmet>
            <meta name="description" content="The first computed restaurants ranking. Click to see the best 50 restaurants in Tel-Aviv, based on local and global critics and the social network." />
            <meta name="google-site-verification" content="1Kvj_wFainPDq-o1xk00HtHNTqs0evpj3THaG3WxRyw" />
            <title itemprop="name">Resty - Tel Aviv Top Restaurants</title>
            <script type="application/ld+json">
            {`{
                "@context": "http://schema.org",
                "@type": "BreadcrumbList",
                "itemListElement":
                [{
                    "@type": "ListItem",
                    "position": 1,
                    "item": {
                        "@id": "https://www.resty.co.il/en/about",
                        "name": "About"
                    }
                },{
                    "@type": "ListItem",
                    "position": 1,
                    "item": {
                    "@id": "https://www.resty.co.il/en/reviews",
                    "name": "Reviews"
                    }
                }]
            }`}
            </script>

            {/*<!--  Essential META Tags -->*/}
            <meta property="og:title" content="Resty - Tel Aviv Top Restaurants" />
            <meta property="og:description" content="The first computed restaurants ranking. Click to see the best 50 restaurants in Tel-Aviv, based on local and global critics and the social network." />
            <meta property="og:image" content="https://resty.co.il/logo512.png" />
            <meta property="og:url" content="http://www.resty.co.il/index.html" />
            <meta property="og:type" content="website" url="http://www.resty.co.il" />
            <meta name="twitter:card" content="summary" />
            <meta name="twitter:title" content="Resty - Tel Aviv Top Restaurants" />
            <meta name="twitter:description" content="The first computed restaurants ranking. Click to see the best 50 restaurants in Tel-Aviv, based on local and global critics and the social network." />
            <meta name="twitter:image" content="https://resty.co.il/logo512.png" />
            <meta name="twitter:site" content="@kadishay" />
            <meta name="twitter:creator" content="@kadishay" />

            {/*<!--  Non-Essential, But Recommended -->*/}
            <meta property="og:site_name" content="Resty - Tel Aviv Top Restaurants" />
            <meta property="twitter:image:alt" content="Resty" />

            {/*<!--  Non-Essential, But Required for Analytics -->*/}
            <meta property="fb:app_id" content="1735189736605848" />
            <meta name="keywords" content="מסעדות בתל אביב, מסעדות, המסעדות הכי טובות בתל אביב, מסעדות מומלצות, מסעדות מומלצות בתל אביב, best restaurants in tel aviv, restaurants in tel aviv, tel aviv top restaurants" />
          </Helmet>
        :
          <Helmet>
            <meta name="description" content="דירוג המסעדות הדיגיטלי הראשון בעולם. כנסו לראות את רשימת מאה המסעדות המומלצות בתל אביב לפי מבקרי מסעדות מהארץ והעולם ולפי הרשת החברתית." />
            <meta name="google-site-verification" content="1Kvj_wFainPDq-o1xk00HtHNTqs0evpj3THaG3WxRyw" />
            <title itemprop="name">Resty - המסעדות הכי טובות בתל אביב</title>
            <script type="application/ld+json">
            {`{
                "@context": "http://schema.org",
                "@type": "BreadcrumbList",
                "itemListElement":
                [{
                    "@type": "ListItem",
                    "position": 1,
                    "item": {
                        "@id": "https://www.resty.co.il/about",
                        "name": "About"
                    }
                },{
                    "@type": "ListItem",
                    "position": 1,
                    "item": {
                        "@id": "https://www.resty.co.il/reviews",
                        "name": "Reviews"
                    }
                }]
            }`}
            </script>

            {/*<!--  Essential META Tags -->*/}
            <meta property="og:title" content="Resty - המסעדות הכי טובות בתל אביב" />
            <meta property="og:description" content="דירוג המסעדות הדיגיטלי הראשון בעולם. כנסו לראות את רשימת מאה המסעדות המומלצות בתל אביב לפי מבקרי מסעדות מהארץ והעולם ולפי הרשת החברתית." />
            <meta property="og:image" content="https://resty.co.il/logo512.png" />
            <meta property="og:url" content="http://www.resty.co.il/index.html" />
            <meta property="og:type" content="website" url="http://www.resty.co.il" />
            <meta name="twitter:card" content="summary" />
            <meta name="twitter:title" content="Resty - המסעדות הכי טובות בתל אביב" />
            <meta name="twitter:description" content="דירוג המסעדות הדיגיטלי הראשון בעולם. כנסו לראות את רשימת מאה המסעדות המומלצות בתל אביב לפי מבקרי מסעדות מהארץ והעולם ולפי הרשת החברתית." />
            <meta name="twitter:image" content="https://resty.co.il/logo512.png" />
            <meta name="twitter:site" content="@kadishay" />
            <meta name="twitter:creator" content="@kadishay" />

            {/*<!--  Non-Essential, But Recommended -->*/}
            <meta property="og:site_name" content="Resty - המסעדות הכי טובות בתל אביב" />
            <meta property="twitter:image:alt" content="Resty" />

            {/*<!--  Non-Essential, But Required for Analytics -->*/}
            <meta property="fb:app_id" content="1735189736605848" />
            <meta name="keywords" content="מסעדות בתל אביב, מסעדות, המסעדות הכי טובות בתל אביב, מסעדות מומלצות, מסעדות מומלצות בתל אביב, best restaurants in tel aviv, restaurants in tel aviv, tel aviv top restaurants" />
          </Helmet>
      }
      <ScrollToTop>
        <div className={`App ${'app-'+lang}`} id='App'>
          <div className="App-menu" id='App-menu' >
            <Link to={`${t.isEn?'/en':''}/`}><div className="resty-menu-logo" /></Link>
            <Link to={`${t.isEn?'/en':''}/`}><div className="resty-menu-title" > RESTY </div></Link>
            <div className={`App-menu-links ${ menuVisible ? '' : 'App-menu-links-hidden'}`}>
              {isMobile ? 
                <div className="resty-link-menu-logo"> 
                  <div className="resty-link-menu-logo-text">
                  RESTY
                  </div>
                  <img 
                    className="resty-link-menu-logo-leaves"
                    src={leaves} 
                    title="Resty Logo"
                    alt="Resty Logo"
                    />
                </div> 
                : ''}
              <div className={`App-menu-links-wrapper`} itemscope itemtype="https://schema.org/BreadcrumbList">
                {!isMobile ?
                  <menu className={`App-menu-links-container`}>
                    <li onClick={()=>{setMenuVisible(false);setLang(t.isEn?LANGS.he:LANGS.en)}} itemprop="itemListElement" itemscope itemtype="https://schema.org/ListItem"><Link to={"/"+(t.isEn?'':'en')} itemprop="item" itemprop="name" className={otherLang==='en'? 'app-menu-local-heb-eng' : '' } >{t.translate(otherLang)}</Link><meta itemprop="position" content="5" /></li>
                    <li onClick={()=>{setMenuVisible(false)}} itemprop="itemListElement" itemscope itemtype="https://schema.org/ListItem"><a itemprop="item" itemprop="name" href="mailto:anonymous@resty.co.il">{t.translate('header_contact')}</a><meta itemprop="position" content="4" /></li>
                    <li onClick={()=>{setMenuVisible(false)}} itemprop="itemListElement" itemscope itemtype="https://schema.org/ListItem"><Link to={`${t.isEn?'/en':''}/reviews`} itemprop="item" itemprop="name">{t.translate('header_reviews')}</Link><meta itemprop="position" content="3" /></li>
                    <li onClick={()=>{setMenuVisible(false)}} itemprop="itemListElement" itemscope itemtype="https://schema.org/ListItem"><Link to={`${t.isEn?'/en':''}/about`} itemprop="item" itemprop="name">{t.translate('header_about')}</Link><meta itemprop="position" content="2" /></li>
                    <li onClick={()=>{setMenuVisible(false)}} itemprop="itemListElement" itemscope itemtype="https://schema.org/ListItem"><Link to={"/"+(t.isEn?'en':'')} itemprop="item" itemprop="name">{t.translate('header_ranking')}</Link><meta itemprop="position" content="1" /></li>
                  </menu>
                  :
                  <menu className={`App-menu-links-container`}>
                    <li onClick={()=>{setMenuVisible(false)}} itemprop="itemListElement" itemscope itemtype="https://schema.org/ListItem"><Link to={"/"+(t.isEn?'en':'')} itemprop="item" itemprop="name">{t.translate('header_ranking')}</Link><meta itemprop="position" content="1" /></li>
                    <li onClick={()=>{setMenuVisible(false)}} itemprop="itemListElement" itemscope itemtype="https://schema.org/ListItem"><Link to={`${t.isEn?'/en':''}/about`} itemprop="item" itemprop="name">{t.translate('header_about')}</Link><meta itemprop="position" content="2" /></li>
                    <li onClick={()=>{setMenuVisible(false)}} itemprop="itemListElement" itemscope itemtype="https://schema.org/ListItem"><Link to={`${t.isEn?'/en':''}/reviews`} itemprop="item" itemprop="name">{t.translate('header_reviews')}</Link><meta itemprop="position" content="3" /></li>
                    <li onClick={()=>{setMenuVisible(false)}} itemprop="itemListElement" itemscope itemtype="https://schema.org/ListItem"><a itemprop="item" itemprop="name" href="mailto:anonymous@resty.co.il">{t.translate('header_contact')}</a><meta itemprop="position" content="4" /></li>
                    <li onClick={()=>{setMenuVisible(false);setLang(t.isEn?LANGS.he:LANGS.en)}} itemprop="itemListElement" itemscope itemtype="https://schema.org/ListItem"><Link to={"/"+(t.isEn?'':'en')} itemprop="item" itemprop="name" className={otherLang==='en'? 'app-menu-local-heb-eng' : '' } >{t.translate(otherLang)}</Link><meta itemprop="position" content="5" /></li>
                  </menu>
                }
              </div>
            </div>
            {isMobile ? 
              <div className="App-menu-mobile-menu-toggle" onClick={()=>{setMenuVisible(!menuVisible)}}> 
                <img 
                  className="App-menu-mobile-menu-toggle-icon"
                  src={menuVisible? x : hamburger} 
                  title="Resty Menu Toggle"
                  alt="Resty Menu Toggle"
                  />
              </div> 
              : ''}
          </div>
          <Switch>
            <Route path="/reviews">
              <div className="App-content"> 
                <Reviews isMobile={isMobile} lang={LANGS.he}/>
              </div>
            </Route>
            <Route path="/about">
              <div className="App-content"> 
                <About isMobile={isMobile} lang={LANGS.he}/>
              </div>
            </Route>
            <Route path="/restaurants/:restId">
              <div className="App-rest"> 
                <Rest isMobile={isMobile} lang={LANGS.he}/>
              </div>
            </Route>
            <Route path="/en/reviews">
              <div className="App-content"> 
                <Reviews isMobile={isMobile} lang={LANGS.en}/>
              </div>
            </Route>
            <Route path="/en/about">
              <div className="App-content"> 
                <About isMobile={isMobile} lang={LANGS.en}/>
              </div>
            </Route>
            <Route path="/en/restaurants/:restId">
              <div className="App-rest"> 
                <Rest isMobile={isMobile} lang={LANGS.en}/>
              </div>
            </Route>
            <Route path="/en">
              <List isMobile={isMobile} lang={LANGS.en}/>
            </Route>
            <Route path="/404" component={NotFoundPage} status={404} />
            <Route exact path="/">
              <List isMobile={isMobile} lang={LANGS.he}/>
            </Route>
            <Redirect to="/404" />
          </Switch>
        </div>
      </ScrollToTop>
    </Router>
  );
}

export default App;