
import * as firebase from "firebase/app";
import "firebase/analytics";
import "firebase/performance";

// TODO: Replace the following with your app's Firebase project configuration
const firebaseConfig = {
    apiKey: "AIzaSyCMwlMAu6zdqjFgEiCuLqEgyU486HBiNCA",
    authDomain: "resty-819e4.firebaseapp.com",
    databaseURL: "https://resty-819e4.firebaseio.com",
    projectId: "resty-819e4",
    storageBucket: "resty-819e4.appspot.com",
    messagingSenderId: "846021525259",
    appId: "1:846021525259:web:57de4d18938bff0b676c0e",
    measurementId: "G-P1PR0Y898G"
  };
// Initialize Firebase
firebase.initializeApp(firebaseConfig);
firebase.analytics();
const perf = firebase.performance();

const FirebaseObj = {
    init: ()=>{},
    perf: perf,
    fb: firebase,
    aLog: firebase.analytics().logEvent
};

export default FirebaseObj;