import React, { useState, useEffect } from 'react';
import './List.css';
import CardsLayout from './CardsLayout';
import data from './Data';
import commons from './Commons';
import firebase from './Firebase';
import logo from './img/logo-mini.png';
import t from './Translations';
import {Helmet} from "react-helmet";

/* Start material */
import CategoryList from './CategoryList';
/* End material */

function cardsCategoryFilter(data, categories) {
  let result = data;
  if (categories) { 
    categories = categories.map(item=>item.cat_key);
    result = data.filter((item)=>JSON.parse(item.categories).some((cat)=>categories.indexOf(cat)!==-1));
  }
  return result;
}

function dataPrep(data, categories = null, amount = 50) {
  let filter = "overall_order";
  let result = cardsCategoryFilter(data, categories);

  return result
    .sort(function(a, b){return a[filter]-b[filter]}) //sort by filter
    .filter((card)=>card.overall_order<=50) //show no more then rank 50
    .slice(0,(amount<50)?amount:50); //show no more then 50 cards total
}

function hasMoreCards(data, categories = null, amount = 50) {
  let result = cardsCategoryFilter(data, categories);
  return (result.length > amount) ? true : false; 
}

function filterData(data, categories, update, amount) {
  let filteredData = [];
  filteredData = dataPrep(data, categories, amount);
  update(filteredData);
}
  
let pageHeaderElement = null;
let isMenuLogo = false;
function scrollLogo() {
  if (!pageHeaderElement) {
    pageHeaderElement = document.getElementById("App-menu");
  }

  if (window.scrollY > 100) {
    if (!isMenuLogo) {
      isMenuLogo = true;
      pageHeaderElement.classList.add("App-menu-logo");
    }
  } else {
    if (isMenuLogo) {
      isMenuLogo = false;
      pageHeaderElement.classList.remove("App-menu-logo");
    }
  }
}

let categoriesArr = [];

function List(props) {
  const CARDS_BATCH_FIRST = (props.isMobile) ? 11 : 23;
  const CARDS_BATCH_NEXT = (props.isMobile) ? 14 : 25;

  // Declare a new state variable, which we'll call "count"
  const [categories, setCategory] = useState(null);
  const [amount, setAmount] = useState(CARDS_BATCH_FIRST);
  const [cards, setCards] = useState(dataPrep(data, categories, amount));
  const [shouldShowMore, setShowMore] = useState(false);

  //logo scroll
  useEffect(() => {
    window.addEventListener('scroll', scrollLogo);
  
    return () => {
      window.removeEventListener('scroll', scrollLogo);
    }
  }, []);

  //category selected
  useEffect(() => {
    filterData(data, categories, setCards, amount);
  },[categories, amount]);

  useEffect(() => {
    //on both category and filter change - reset amount
    setAmount(CARDS_BATCH_FIRST);
  },[categories]);

  //show more button
  useEffect(() => {
    let moreCardsAvailvle = (amount >= 50) ? false : true;
    if (moreCardsAvailvle) {
      moreCardsAvailvle = hasMoreCards(data, categories, amount);
    }
    setShowMore(moreCardsAvailvle);
  }, [categories, amount]);

  //fit cards to grid eld
  useEffect(() => {
    setTimeout(()=>{
      let container = document.getElementsByClassName('resty-cards-layout')[0].getBoundingClientRect();
      let last = document.querySelectorAll('.resty-cards-layout a:last-child')[0].getBoundingClientRect();
      let conBottom = Math.round(container.bottom);
      let conLeft = Math.round(container.left);
      let conRight = Math.round(container.right);
      let LastBottom = Math.round(last.bottom);
      let LastLeft = Math.round(last.left);
      let LastRight = Math.round(last.right);
            
      if (t.isEn) {
        if ((conBottom !== LastBottom ) || (conRight !== LastRight)) {
          if (hasMoreCards(data, categories, amount)) {
            setAmount(amount+1);
          }
        }
      } else {
        if ((conBottom !== LastBottom ) || (conLeft !== LastLeft)) {
          if (hasMoreCards(data, categories, amount)) {
            setAmount(amount+1);
          }
        }
      }
    },100);
  }, [categories, amount, props.isMobile]);

  //category prep
  useEffect(() => {
    let categoriesObj = {};
    let filteredData = [];
    filteredData = dataPrep(data, null, 50);
    filteredData.forEach(card=>{
      if (card.categories) {
        JSON.parse(card.categories).forEach((cat)=>{
          if (!categoriesObj[cat]) {
            categoriesObj[cat] = 0;
          }
          categoriesObj[cat]++;
        });
      }
    });
    categoriesArr=[];
    Object.keys(categoriesObj).forEach((cat)=>{
      if (categoriesObj[cat] >= 4) { //keep just categories with 4 or more items
        categoriesArr.push({cat:cat,num:categoriesObj[cat]});
      }
    });
    categoriesArr = categoriesArr.sort((a,b)=>{
      //force sort up top categories
      if (a.cat === "israeli") { 
        return -1;
      } else if (b.cat === "israeli") {
        return 1;
      } else if (a.cat === "mediterranean") {
        return -1;
      } else if (b.cat === "mediterranean") {
        return 1;
      } else if (a.cat === "gastronomic") {
        return -1;
      } else if (b.cat === "gastronomic") {
        return 1;
      }
      return b.num-a.num;
    }).map(item=>item.cat);
  });

  const json_data = {
    "@context": "https://schema.org",
    "@type": "Organization",
    "url": "http://www.resty.co.il",
    "name": "Resty - המסעדות הכי טובות בתל אביב",
    "logo": "https://resty.co.il/logo512.png",
    "contactPoint": {
      "@type": "ContactPoint",
      "email": "anonymous@resty.co.il",
      "contactType": "Customer service",
      "telephone": "+972546306797"
    }
  }

  return (
    <div className="App-list" >
       <Helmet>
        <script type="application/ld+json">
          {JSON.stringify(json_data)}
        </script>
      </Helmet>
      <header className="list-header">
        <img src={logo} className="list-logo-img" alt="Resty Logo" title="המסעדות הכי טובות בתל אביב" />
        {t.isEn ? <h2 className="list-header-sub-title">{t.translate('tel_aviv')}</h2> : '' }
        <h1 className="list-header-title">{
          t.translate('top_title', { number: '50' }).split(' ').map((word)=>{
            if (word === '50') {
              return <b>{word} </b>;
            }
            return word + ' ';
          })
        }</h1>
        {!t.isEn ? <h2 className="list-header-sub-title">{t.translate('tel_aviv')}</h2> : '' }
      </header>

      <CategoryList categories={categoriesArr} lang={props.lang} updateCategory={(val)=>{
        commons.ga.event({
          category: "filter-category",
          action: !val ? '0' : val.length + '',
          label: !val ? '["all"]' : JSON.stringify(val.map(item=>item.cat_key))
        });
        firebase.aLog("filter-category");
        setCategory(val);
      }}/>

      <CardsLayout cards={cards} canAdd={shouldShowMore} addCards={()=>{
        commons.ga.event({
          category: "show-more",
          action: 'show-more-' + (props.isMobile?'mobile':'desktop'),
          label: amount + ''
        });
        firebase.aLog("show-more");
        setAmount(amount+CARDS_BATCH_NEXT);
      }}/>
  </div>
  );
}

export default List;
