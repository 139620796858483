import React from 'react';
import './RestRankSection.css';
import AnimatedNumber from 'react-animated-number';
import t from './Translations';

import leaves from "./img/leaves.svg";

function rankSingle(rank, text) {
    return <div className={`resty-rank-bar ${(!rank || (rank === "0.0")) ? 'resty-rank-bar-disabled' : ''}`} itemprop="ratingValue" content={rank} >
      <img 
        className="resty-rank-leaves"
        src={leaves} 
        title={text}
        alt="Resty icon"
        />
      <div className="resty-rank-leaves-filter" />
      <AnimatedNumber component="text" value={rank}
            style={{
                transitionProperty:
                    'background-color, color, opacity',
            }}
            frameStyle={perc => (
                /*perc === 100 ? {} : {backgroundColor: '#ffeb3b'}*/ 
                perc
            )}
            className="resty-rank-number"
            duration={2500}
            formatValue={n => (Math.round(n*10)/10).toFixed(1)}/>
      <div className="resty-rank-text">{text}</div>
    </div>
}

function RestRankSection(props) {
    return <div className='resty-rest-ranking-part'>
        {!props.isMobile ? 
            <div className='resty-rest-ranking-section'>
                {rankSingle(props.social_rank, t.translate('filter_social'))}
            </div> : ''
        }
        
        <div className='resty-rest-ranking-section'>
            {rankSingle(props.israel_rank, t.translate('filter_israel'))}
        </div>
        
        <div className='resty-rest-ranking-section' itemprop="starRating" itemscope itemtype="http://schema.org/Rating" >
            {rankSingle(props.overall_rank, t.translate('filter_resty'))}
        </div>

        <div className='resty-rest-ranking-section'>
            {rankSingle(props.global_rank, t.translate('filter_global'))}
        </div>

        {props.isMobile ? 
            <div className='resty-rest-ranking-section'>
                {rankSingle(props.social_rank, t.translate('filter_social'))}
            </div> : ''
        }

        <div className='resty-rest-ranking-section'>
            {rankSingle(props.house_rank, t.translate('filter_house'))}
        </div>
    </div>;
  }

export default RestRankSection;
