import ReactGA from 'react-ga';

//import MobileDetect from 'mobile-detect';

const trackingId = 'UA-112192545-3';
ReactGA.initialize(trackingId);


//const md = new MobileDetect(navigator.userAgent);
//const isMobile = md.mobile();

export default {
    FILTERS : {
        'overall': 'overall',
        'social': 'social',
        'israel': 'israel',
        'global': 'global',
        'house': 'house'
    },
    leaves : 'leaves',
    ga: ReactGA
};