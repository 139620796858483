import React from 'react';
import './Card.css';

import {Link} from "react-router-dom";

import defualt_img_webp from './img/rest/placeholder_even.webp';
import defualt_img_jpg from './img/rest/placeholder_even.jpg';
import star from './img/star.svg';

import t from './Translations';

function importAll(r) {
    return r.keys().map(r);
}

function canUseWebP() {
    var elem = document.createElement('canvas');

    if (!!(elem.getContext && elem.getContext('2d'))) {
        // was able or not to get WebP representation
        return elem.toDataURL('image/webp').indexOf('data:image/webp') == 0;
    }

    // very old browser like IE 8, canvas not supported
    return false;
}

const webpSupport = canUseWebP(); 
  
const images = importAll(require.context('./img/rest', false, /\.(png|jpe?g|svg|webp)$/));

const REGULAR_CARD_LIMIT = 50;

class Card extends React.Component {
    render() {
        const data = this.props.data;
        if (data === 'list-end-placeholder') {
            return <Link className='resty-end-list resty-card-reg'>
                <div className='resty-card'>
                </div>
            </Link>
        }
        let name = data.detail_hebrew_name || data.detail_eng_name || data.name;
        if (t.isEn && data.detail_eng_name) {
            name = data.detail_eng_name;
        }
        const order = this.props.order;
        const url = data.key.toLowerCase();

        let regularCard = order <= REGULAR_CARD_LIMIT;

        let cardType = 
            (order==1)? 'resty-card-big' :
                (order<=3)? 'resty-card-medium' :
                    (regularCard) ? 'resty-card-reg' : 
                        'resty-card-small';
        let img_suffix = 
            (order==1)? '_even_big' :
                (order<=3)? '_wide' :
                    (regularCard) ? '_even' : 
                        '';

        let thumbnail = (data.detail_img) ? 
            images.filter((item) => {
                let re = new RegExp(data.detail_img + '_thumb'+img_suffix+'\.[a-zA-Z0-9]{8}\.' + ((webpSupport) ? 'webp' : 'jpg'));
                return item.match(re);
            }) : ((webpSupport) ? defualt_img_webp : defualt_img_jpg);
        
        return <Link to={`${t.isEn?'/en':''}/restaurants/${url}`} className={`${'resty-card-layout-'+order} ${cardType} ${data.corona=="TRUE" ? 'corona' : ''} `}>
            <div className='resty-card'>
                {data.most_popular==="popular" ? 
                    <div alt='popular' className="resty-card-ribbon resty-card-ribbon-pop" > 
                      <img 
                        className="resty-card-star"
                        src={star} 
                        title="Resty Rank Star"
                        alt="Resty Rank Star"
                        />
                      {order<=3 ? t.translate("popular"): ''}
                    </div>
                : ''}
                {data.new==="new" ? 
                <div alt='new' className="resty-card-ribbon resty-card-ribbon-new" > 
                      <img 
                        className="resty-card-star"
                        src={star} 
                        title="Resty Rank Star"
                        alt="Resty Rank Star"
                        />
                      {order<=3 ? t.translate("new"): ''}
                    </div>
                : ''}
                {regularCard ? 
                  <div className='resty-card-top'>
                      <picture>
                          <source srcset={defualt_img_webp} type="image/webp" />
                          <source srcset={defualt_img_jpg} type="image/jpeg" /> 
                          <img src={defualt_img_jpg} alt={name} title={name} className="resty-card-top-img" />
                      </picture>
                      <picture>
                          <source srcset={thumbnail} type="image/webp" />
                          <source srcset={thumbnail} type="image/jpeg" /> 
                          <img src={thumbnail} alt={name} title={name} className="resty-card-top-img" />
                      </picture>
                  </div> 
                  : ''}
                <div className={`resty-card-name ${t.isEn || (name.match(/^[a-zA-Z]/)!==null) ? 'resty-card-name-english resty-card-name-english-in-heb' : ''} ${name.length>=16 ? 'resty-card-name-long' : ''}`}>
                  {name}
                </div>
                <div className='resty-card-rank'>{order}</div>
            </div>
        </Link>
    }
}

export default Card;
