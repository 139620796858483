import React from 'react';
import './SocialBar.css';

import fb from "./img/fb.svg";
import insta from "./img/insta.svg";
import ta from "./img/ta.svg";
import mail from "./img/mail.svg";
import map from "./img/map.svg";
import phone from "./img/phone.svg";
import web from "./img/web.svg";

class SocialBar extends React.Component {
    render() {
        return <div className='resty-social-bar'>
            {this.props.fb ? 
                <a href={this.props.fb} target="_blank" rel="noopener noreferrer"> 
                    <img 
                      className="resty-social-bar-item"
                      src={fb} 
                      alt="דף המסעדה ב Facebook"
                      title="דף המסעדה ב Facebook"
                      />
                </a> : ''
            }
            {this.props.insta ? 
                <a href={this.props.insta} target="_blank" rel="noopener noreferrer"> 
                    <img 
                      className="resty-social-bar-item"
                      src={insta} 
                      alt="דף המסעדה ב Instagram"
                      title="דף המסעדה ב Instagram"
                      />
                </a> : ''
            }
            {this.props.ta ? 
                <a href={this.props.ta} target="_blank" rel="noopener noreferrer"> 
                    <img 
                      className="resty-social-bar-item"
                      src={ta} 
                      alt="דף המסעדה ב TripAdvisor"
                      title="דף המסעדה ב TripAdvisor"
                      />
                </a> : ''
            }
            {this.props.mail ? 
                <a href={`mailto:${this.props.mail}`} rel="noopener noreferrer" itemprop="email"> 
                    <img 
                      className="resty-social-bar-item"
                      src={mail} 
                      alt="דואר אלקטרוני"
                      title="דואר אלקטרוני"
                      />
                </a> : ''
            }
            {this.props.map ? 
                <a href={this.props.map} target="_blank" rel="noopener noreferrer"> 
                    <img 
                      className="resty-social-bar-item"
                      src={map} 
                      alt="המסעדה במפה"
                      title="המסעדה במפה"
                      />
                </a> : ''
            }
            {this.props.phone ? 
                <a href={`tel:${this.props.phone}`} itemprop="telephone">
                    <img 
                      className="resty-social-bar-item"
                      src={phone} 
                      alt="התקשר למסעדה"
                      title="התקשר למסעדה"
                      />
                </a> : ''
            }
            {this.props.web ? 
                <a href={this.props.web} target="_blank" rel="noopener noreferrer" itemprop="url"> 
                    <img 
                      className="resty-social-bar-item"
                      src={web} 
                      alt="אתר המסעדה"
                      title="אתר המסעדה"
                      />
                </a> : ''
            }
        </div>;
    }
}
export default SocialBar;
