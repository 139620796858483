import React from 'react';
import {
    Link
  } from "react-router-dom";
import './NotFoundPage.css';
import t from './Translations';
import NotFoundImg from "./img/resty-404.png";
import commons from './Commons';
import firebase from './Firebase';

function NoFoundPage() {
    commons.ga.event({
        category: "404",
        action: "404",
        label: "404"
    });
    firebase.aLog("404");
    return (
        <Link className="resty-not-found" to="/">
            <div>
                <img 
                    className="resty-not-found-image"
                    src={NotFoundImg} 
                    alt="Restaurant not found"
                    title="Restaurant not found"
                    />
                <h1 className="resty-not-found-title">{t.translate("404")}</h1>
            </div>
        </Link>
    );
}

export default NoFoundPage;