import React, {useEffect} from 'react';
import {PageSlides, SlideParallaxType} from 'react-page-slides';
import './About.css';

import first from './img/about/louis-hansel-dNgwsE2HRJw-unsplash.jpg';
import first_mobile from './img/about/louis-hansel-dNgwsE2HRJw-unsplash_mobile.jpg';
import second from './img/about/louis-hansel-ZwpX0AJ7GV8-unsplash.jpg';
import second_mobile from './img/about/louis-hansel-ZwpX0AJ7GV8-unsplash_mobile.jpg';
import third from './img/about/louis-hansel-U6-KvV7HlMk-unsplash.jpg';
import third_mobile from './img/about/louis-hansel-U6-KvV7HlMk-unsplash_mobile.jpg';
import fourth from './img/about/louis-hansel-C5YnAXEEZS0-unsplash.jpg';
import fourth_mobile from './img/about/louis-hansel-C5YnAXEEZS0-unsplash_mobile.jpg';
import fifth from './img/about/julien-sarazin-n3mK4n_nF7g-unsplash.jpg';
import fifth_mobile from './img/about/julien-sarazin-n3mK4n_nF7g-unsplash_mobile.jpg';
import sixth from './img/about/zoltan-tasi-k4kK9_OQk24-unsplash.jpg';
import sixth_mobile from './img/about/zoltan-tasi-k4kK9_OQk24-unsplash_mobile.jpg';

const FirstPage = () => (
  <div className="resty_about_page first_page" >
      <h1> Resty </h1>

      <p>Resty הוא מבקר המסעדות הדיגיטלי הראשון בעולם.</p>
      <p>Resty חוקר את רשת האינטרנט במטרה למצוא את כל המידע על כל המסעדות בעיר מסויימת (מתחילים מתל אביב ונמשיך לגדול בקרוב).</p>
      <p>Resty מחפש את הדירוגים של המבקרים המנוסים בארץ ובעולם וגם שלכם ברשתות החברתיות</p>
      <p>
        כל המידע הזה משוכלל לציון אחד סופי - <a href="/"> הדירוג של Resty - המסעדות הכי טובות בתל אביב</a>
      </p>
  </div>
)

const SecondPage = () => (
  <div className="resty_about_page second_page" >
      <h1> Resty ברשת </h1>
      <p>
      Resty חוקר את הרשתות החברתיות ואוסף את הציונים שאתם נתתם למסעדות שאתם הכי אוהבים.
      </p>
      <p>
      הציונים מגיעים מההצבעות שלכם ב 
      Google Maps, Facebook, TripAdvisor, OpenTable, Zap Rest.
      </p>
  </div>
)

const ThirdPage = () => (
  <div className="resty_about_page third_page" >
      <h1> מבקרי המסעדות </h1>
      <p>Resty לוקח בחשבון גם את המקצוענים.</p>
      <p>
        הוא מחפש ביקורות מהארץ ומהעולם ומנתח אותן בעזרת אלגוריתם לומד של ניתוח סנטימנט - על מנת להמיר אותן לציון מדויק.
      </p>
      <p>
        בנוסף Resty מוצא את כל הדירוגים והרשימת ומוסיף אותך לדירוג, לדוגמא: 
        Gault&Millau, Eater, La Liste, TimeOut
      </p>
  </div>
)

const FourthPage = () => (
  <div className="resty_about_page fourth_page" >
      <h1> אנונימוס </h1>
      <p>
        אחרון חביב, הוא מבקר הבית שלנו Anonymos שעובד קשה בלמצוא את המסעדות הכי שוות בעיר
      </p>
  </div>
)

const FifthPage = () => (
  <div className="resty_about_page fifth_page" >
      <h1> אל תתביישו! </h1>

      <p>
      יש לכם שאלות? הצעות? לא הבנתם בדיוק למה המסעדה החדשה קיבלה ציון מסוים? שלחו לנו מייל ונשמח לענות.
      </p>
      <p>
        <a href="mailto:anonymous@resty.co.il">Anonymous@Resty.co.il</a>
      </p>
  </div>
)

const Ranking = () => (
  <a
      href="/"
      rel="noopener"
      className="button ranking_button"
  >
      לדירוג
  </a>
);

function About(props) {
  //show logo always on this page
  useEffect(() => {
    document.getElementById("App-menu").classList.add("App-menu-logo");
  
    return () => {
      document.getElementById("App-menu").classList.remove("App-menu-logo");
    }
  }, []);

  useEffect(() => {
    document.body.classList.add("resty-about");
  
    // returned function will be called on component unmount 
    return () => {
      document.body.classList.remove("resty-about");
    }
  }, []);

  let first_image_url = (props.isMobile) ? first_mobile : first;
  let second_image_url = (props.isMobile) ? second_mobile : second;
  let third_image_url = (props.isMobile) ? third_mobile : third;
  let fourth_image_url = (props.isMobile) ? fourth_mobile : fourth;
  let fifth_image_url = (props.isMobile) ? fifth_mobile : fifth;
  let sixth_image_url = (props.isMobile) ? sixth_mobile : sixth;

  const slides = [
    {
      content: <FirstPage />,
      style: {
        backgroundImage: 'url("'+first_image_url+'")'
      }
    },
    {
      content: <SecondPage />,
      style: {
        backgroundImage: 'url("'+second_image_url+'")'
      }
    },
    {
      content: <ThirdPage />,
      style: {
        backgroundImage: 'url("'+third_image_url+'")'
      }
    },
    {
      content: <FourthPage />,
      style: {
        backgroundImage: 'url("'+fourth_image_url+'")'
      }
    },
    {
      content: <FifthPage />,
      style: {
        backgroundImage: 'url("'+fifth_image_url+'")'
      }
    },
    {
      content: <Ranking />,
      style: {
        backgroundImage: 'url("'+sixth_image_url+'")'
      }
    },
  ];


  return (
    <div style={{width:'100%'}} className={ props.isMobile ? 'about-mobile' : '' } >
      <PageSlides
        enableAutoScroll={true}
        transitionSpeed={1000}
        slides={slides}
        parallax={{
          offset: 0.6,
          type: SlideParallaxType.reveal
        }}
      />
    </div>
  );
}

export default About;
