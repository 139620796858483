import React, {useEffect} from 'react';
import './Rest.css';
import {
    useParams, Redirect
} from "react-router-dom";
import Chip from '@material-ui/core/Chip';
import {Helmet} from "react-helmet";
import RestRankSection from "./RestRankSection";

import SocialBar from './SocialBar';

import data from './Data';

import commons from './Commons';
import firebase from './Firebase';
import t from './Translations';
//import GaultMillau from './GaultMillau';


import { compose, withProps } from "recompose";
import {
  withScriptjs,
  withGoogleMap,
  GoogleMap,
  Marker
} from "react-google-maps";


/*
import coin from "./img/shekel.svg";
import coin_blank from "./img/shekel_empty.svg";
*/


const MapComponent = compose(
  withProps({
    
     //Note: create and replace your own key in the Google console.
     //https://console.developers.google.com/apis/dashboard
     //The key "AIzaSyBkNaAGLEVq0YLQMi-PYEMabFeREadYe1Q" can be ONLY used in this sandbox (no forked).
     
    googleMapURL:
      "https://maps.googleapis.com/maps/api/js?key=AIzaSyDxdjstpN2bGI-TJyIfi41bwhMio-psr9E&v=3.exp&libraries=geometry,drawing,places",
    loadingElement: <div style={{ height: `100%` }} />,
    containerElement: <div style={{ height: `400px` }} />,
    mapElement: <div style={{ height: `100%` }} />
  }),
  withScriptjs,
  withGoogleMap
)(props => (
  <GoogleMap 
    defaultZoom={18} 
    defaultCenter={{ lat: parseFloat(props.lat), lng: parseFloat(props.lng) }} 
    defaultOptions={{disableDefaultUI: true, draggable: false, draggableCursor:'default'}}
    >
    {props.isMarkerShown && (
      <Marker position={{ lat: parseFloat(props.lat), lng: parseFloat(props.lng) }} title="yotam" />
    )}
  </GoogleMap>
));

function Rest(props) {
    const { restId } = useParams();
    const rest_data = data.filter(rest=>rest.key.toLocaleLowerCase()===restId)[0]; 
  
    useEffect(() => {
      var today = document.getElementsByClassName("resty-rest-hours-section-today")[0];
      if (today) {
        today.scrollIntoView();
        setTimeout(()=>{
          window.scrollTo(0, 0);
        },0);
      }
    });

    //show logo always on this page
    useEffect(() => {
      document.getElementById("App-menu").classList.add("App-menu-logo");
    
      return () => {
        document.getElementById("App-menu").classList.remove("App-menu-logo");
      }
    }, []);

    if (!rest_data || !rest_data.key) {
      return <Redirect to="/404"></Redirect>
    }
    const key = rest_data.key.toLocaleLowerCase();

    /*
    const price = [];
    for (let i=1; i<=4; i++) {
        if (i<=rest_data.price_level) {
            price.push(<img className="resty-rest-price-coin" src={coin} />);
        } else {
            price.push(<img className="resty-rest-price-coin" src={coin_blank} />);
        }
    }
    */

    let hours = t.isEn ? rest_data.detail_eng_hours : rest_data.detail_heb_hours;

    let today = new Date().getDay();
    if (t.isEn) {
      today = (today===0) ? 6 : today-1; 
    }

    let description = t.translate("description_"+key);
    description = (description && description.indexOf("description") !== 0) ? description : null;
    
    let shortDescription = description ? description : null
    if (shortDescription && shortDescription.length >= 155) {
      shortDescription = shortDescription.substr(0,155);
      shortDescription = shortDescription.substr(0,shortDescription.lastIndexOf(" ")) + "...";
    }

    let detailed = description || `Resty - ${rest_data.detail_hebrew_name} | Resty Score: ${rest_data.overall_rank}, Restaurant Tel-Aviv Rank: #${rest_data.overall_order}`;
    //https://developers.google.com/search/docs/data-types/review-snippet#review-snippet
    let json_data =  {
        "@context":"https://schema.org",
        "@type":"Review",
        "name":rest_data.detail_hebrew_name,
        "description": detailed,
        "reviewBody": detailed,
        "itemReviewed": {
            "@type": "Restaurant",
            "image": "https://resty.co.il/logo512.png",
            "name": rest_data.detail_hebrew_name,
            "priceRange": "$".repeat(rest_data.price_level),
            "telephone": rest_data.detail_phone_number,
            "servesCuisine": rest_data.categories[0], 
            "address" :{
              "@type": "PostalAddress",
              "streetAddress": rest_data.detail_address_heb,
              "addressLocality": "Tel Aviv",
              "addressCountry": "IL"
            },
            "geo": {
                "@type":"GeoCoordinates",
                "latitude":rest_data.detail_location_lat,
                "longitude":rest_data.detail_location_lng
            },
        },
        "reviewRating": {
            "@type": "Rating",
            "ratingValue":rest_data.overall_rank,
            "worstRating":1,
            "bestRating":5
        },
        "author": {
            "@type": "Organization",
            "name": "Resty"
        },
        "publisher": {
            "@type": "Organization",
            "name": "Resty"
        }
    }

    const title = "Resty | " +
      ((!t.isEn) ? t.translate("top_rest") + " | " : "" ) + 
      (t.isEn ? 
        rest_data.detail_eng_name + " " + t.translate("restaurant") : 
        t.translate("restaurant") + " " + rest_data.detail_hebrew_name) +
      ((t.isEn) ? " | " + t.translate("top_rest") : "" );
    const address = t.isEn ? rest_data.detail_address_eng : rest_data.detail_address_heb;

    return <div className="Restaurant-Page" itemscope itemtype="http://schema.org/Review">
        <Helmet>
            <title>{title}</title>
            <meta name="description" content={shortDescription? shortDescription : detailed} />

            <meta property="og:title" content={title} />
            <meta property="og:description" content={shortDescription? shortDescription : detailed} />
            <meta property="og:image" content="https://resty.co.il/logo512.png" />
            <meta property="og:url" content={`http://www.resty.co.il/restaurants/${key}`} />
            <meta property="og:type" content="article" />
            <meta property="twitter:card" content="summary" />
            <meta property="og:site_name" content={`Resty`} />
            <meta property="twitter:image:alt" content={shortDescription? shortDescription : detailed} />

            <meta name="twitter:card" content="summary" />
            <meta name="twitter:title" content={title}  />
            <meta name="twitter:description" content={shortDescription? shortDescription : detailed} />
            <meta name="twitter:image" content="https://resty.co.il/logo512.png" />
            <meta name="twitter:site" content="@kadishay" />
            <meta name="twitter:creator" content="@kadishay" />

            <meta property="og:latitude" content={rest_data.detail_location_lat} />
            <meta property="og:longitude" content={rest_data.detail_location_lng} />
            <meta property="og:street-address" content={address} />
            <meta property="og:region" content="Tel Aviv" />

        </Helmet>

        {!props.isMobile ? 
          <RestRankSection 
            social_rank={rest_data.social_rank}
            israel_rank={rest_data.israel_rank}
            overall_rank={rest_data.overall_rank}
            global_rank={rest_data.global_rank}
            house_rank={rest_data.house_rank} 
            isMobile={props.isMobile}
            /> : '' }

        <h1 className={`rest-name-main-title ${(rest_data.detail_hebrew_name.match(/^[a-zA-Z]/)!==null) ? 'resty-rest-name-english-in-heb' : ''}`}>
          <span> {'#' + rest_data.overall_order + ' '} </span>
          {t.isEn ? rest_data.detail_eng_name : rest_data.detail_hebrew_name }
        </h1>
        <h2 itemprop="streetAddress">{t.isEn ? rest_data.detail_address_eng : rest_data.detail_address_heb }</h2>

        <div className='resty-rest-categories'>
          {rest_data.categories ? JSON.parse(rest_data.categories).map((cat) => {
            return (
                <Chip
                    className="resty-category-label"
                    key={t.translate("cat_"+cat)}
                    label={t.translate("cat_"+cat)}
                />
            );
          }) : ''}
        </div>

        {props.isMobile ? 
          <RestRankSection 
            social_rank={rest_data.social_rank}
            israel_rank={rest_data.israel_rank}
            overall_rank={rest_data.overall_rank}
            global_rank={rest_data.global_rank}
            house_rank={rest_data.house_rank} 
            isMobile={props.isMobile}
            /> : '' }

        {/*
        <p> 
            <span>כמות ביקורות ברשת החברתית:</span> 
            <span>{rest_data.social_amount}</span> 
        </p>

        <p> 
            <span>מסעדה פופולרית:</span> 
            <span>{!!rest_data.most_popular}</span> 
        </p>
        <p> 
            <span>מסעדה חדשה:</span> 
            <span>{!!rest_data.new}</span> 
        </p>

        
        <p> 
            <span>מחיר:</span> 
            <span className="resty-rest-price-container">{price}</span>
        </p>

        ----------------------------------------------------------------------------------------------------
        */}
        
        {(description)? <div className="resty-rest-desc">{description}</div> : ""}
          
        {/*<GaultMillau rank={rest_data.gm_rank} toques={rest_data.gm_toques} link={rest_data.gm_link} />*/}

        {hours ?
          <div className='resty-rest-hours' itemprop="openingHours"> 
            <div className='resty-rest-hours-scroll'>
              {hours.split('|').map((day, i)=>{
                return <div className={`resty-rest-hours-section ${(i===today? 'resty-rest-hours-section-today' : '')}`}> {
                    day.split(',').map((hour)=>{
                      //console.log(hour.split(":"))
                      return <div>
                        {/*hour.split(":").map((item)=>{
                        
                          console.log(item);
                          console.log(!Number.isInteger(parseInt(item[item.length-1])));
                          if (!Number.isInteger(parseInt(item[item.length-1]))) { //if word - meaning name of day, wrrap in div, else just return 
                            return <div>{item}:</div>
                          }
                        
                          return item;
                        }).join(":") */}
                        
                        {hour.replace(/יום/g, '').replace(/ /g, '').replace(/[^0-9]:/, '$&\n\n')}
                      </div>
                    })
                } </div>;
              })}
            </div>
          </div>
          : ''}

        {props.isMobile ?  
            <SocialBar 
              ta={rest_data.ta_link} 
              map={rest_data.maps_link} 
              fb={rest_data.fb_link} 
              insta={rest_data.insta_link} 
              mail={rest_data.email} 
              phone={rest_data.detail_phone_number} 
              web={rest_data.website} 
              /> : ''}

        <div className="resty-map-wrapper">
            <MapComponent isMarkerShown lat={rest_data.detail_location_lat} lng={rest_data.detail_location_lng} />
        </div>

        <div className='resty-rest-footer'>
          {!props.isMobile ?  
            <SocialBar 
              ta={rest_data.ta_link} 
              map={rest_data.maps_link} 
              fb={rest_data.fb_link} 
              insta={rest_data.insta_link} 
              mail={rest_data.email} 
              phone={rest_data.detail_phone_number} 
              web={rest_data.website} 
              /> : ''}
          

          {rest_data.reservations ? 
            <div onClick={()=>{
                    var target = rest_data.reservations.indexOf('ontopo') ? 
                      'ontopo' :
                      rest_data.reservations.indexOf('tabit') ? 
                        'tabit' :
                        rest_data.reservations.indexOf('clickatable') ? 
                          'clickatable' :
                          rest_data.reservations.indexOf('rest') ? 
                            'rest' :
                            'other';
                    commons.ga.event({
                        category: "reservation",
                        action: rest_data.key,
                        label: target
                    });
                    firebase.aLog("reservation");
                    window.open(rest_data.reservations, "_blank");
                }} className='resty-rest-reservation'>
                {t.translate('book_table')}
            </div>
          :''}
        </div>

        

        {/*
        <p>
            {JSON.stringify(rest_data)}
        </p>
         */}
    </div>;
  }

export default Rest;

/*
{
  "name": "Topolompopo",
  "key": "topolompopo",
  "detail_hebrew_name": "טופולומפופו",
  "detail_eng_name": "Topolopompo",
  "ta_link": "https://www.tripadvisor.com/Restaurant_Review-g293984-d5489351-Reviews-Topolopompo-Tel_Aviv_Tel_Aviv_District.html",
  "maps_link": "https://g.page/topolopompo-restaurant",
  "fb_link": "https://www.facebook.com/topolopompo",
  "insta_link": "https://www.instagram.com/explore/locations/217650362/topolopompo-/?hl=en",
  "email": "",
  "reservations": "",
  "price_level": "4",
  "website": "",
  "categories": "[ \"steakhouse\", \"bistro_brasserie\"]",
  "detail_address_heb": "הסוללים 14, תל אביב יפו",
  "detail_address_eng": "HaSolelim Street 14, Tel Aviv-Yafo",
  "detail_phone_number": "03-691-0691",
  "detail_website": "http://topolopompo.co.il/",
  "detail_location_lat": "32.0689399",
  "detail_location_lng": "34.7955119",
  "detail_heb_hours": "יום ראשון: 12:00–23:30 | יום שני: 12:00–23:30 | יום שלישי: 12:00–23:30 | יום רביעי: 12:00–23:30 | יום חמישי: 12:00–23:30 | יום שישי: 18:00–23:30 | יום שבת: סגור",
  "detail_eng_hours": "Monday: 12:00 – 11:30 PM | Tuesday: 12:00 – 11:30 PM | Wednesday: 12:00 – 11:30 PM | Thursday: 12:00 – 11:30 PM | Friday: 6:00 – 11:30 PM | Saturday: Closed | Sunday: 12:00 – 11:30 PM",
  "detail_img": "topolompopo",
  "detail_img_credit": "אנונימוס",
  "global_rank": "3.3",
  "global_order": "18",
  "israel_rank": "3.9",
  "israel_order": "8",
  "social_rank": "4.4",
  "social_order": "43",
  "social_amount": "2853",
  "house_rank": "4",
  "house_order": "13",
  "overall_rank": "3.9",
  "overall_order": "8",
  "most_popular": "",
  "new": ""
}
*/